import React from "react";

import HeaderBottomTwo from "../../common/header/HeaderBottomTwo";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import ServiceDetailsContent from "../../components/service-details/ServiceDetailsContent";
import ServiceHowItWork from "../../components/service-details/ServiceHowItWork";
import ServiceFaq from "../../components/service-details/ServiceFaq";
import QuoteWidget from "../../components/widgets/QuoteWidget";
import ContactWidget from "../../components/widgets/ContactWidget";
import ServiceWidget from "../../components/widgets/ServiceWidget";
import FooterTwo from "../../common/footer/FooterTwo";
import HeaderTop from "../../common/header/HeaderTop";
import breadcrumbBg from "../../assets/images/breadcrumb/nursary.jpg";
import ServiceDetailsImg1 from "../../assets/images/service/nursery-1.jpg";

const NurseryService = () => {
  return (
    <>
      <HeaderTop />
      <HeaderBottomTwo />
      <Breadcrumb
        breadcrumbBg={breadcrumbBg}
        heading="Services Details"
        description="Nursery Service"
        currentPage="Services Details"
      />
      <div className="service-details pt-110 pb-110">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-4 pr-50">
              <ServiceWidget />
              <ContactWidget />
              {/* <QuoteWidget /> */}
            </div>
            <div className="col-xl-8 col-lg-8">
              <div className="service-details-wrapper">
                <>
                  <h2>Nursery Service</h2>
                  <p>
                    We are committed to meeting your demands for gardening and landscaping by offering premium nursery plants and all-inclusive services. Our nursery offers a huge range of plants and knowledgeable advice to help you create lovely and flourishing outdoor environments, whether you're a homeowner, a business developer, or a landscape professional.
                  </p>
                  <h2>Extensive Plant Selection</h2>
                  <p>
                  Our nursery offers a wide range of plants that are ideal for Saudi Arabia's particular soil and climate. We provide a wide variety of plant species to fit any choice and gardening endeavor, ranging from decorative trees and shrubs to blooming plants, succulents, and tropical kinds. Our plants are acquired from respected nurseries and growers, guaranteeing their high quality.
                  </p>
                
                  <img
                    src={ServiceDetailsImg1}
                    className="mt-35 mb-35"
                    alt=""
                  />
                  <h2>Healthy and Vigorous Plants</h2>
                  <p>
                  Our nursery plants' vitality and health come first. In order for the plants to be in the best possible shape when they arrive in your hands, our horticulture professionals provide them with attentive nurturing and upkeep. To encourage strong root development and robust growth, we take painstaking care with watering, fertilizing, trimming, and insect management. You will receive plants that are prepared to thrive in your landscape thanks to our dedication to quality.
                  </p>

                  <h2>Nursery Care and Services</h2>
                  <p className="mb-45">
                  We provide thorough nursery care services in addition to plant selection to maintain the health and vitality of your plants. We offer aid with transplanting as well as watering, fertilizing, trimming, and disease and insect management. Whether you are working on a large-scale landscaping project or a tiny home garden, we can help you preserve the beauty and health of your plants.
                  </p>
                  <h2>Convenient Ordering and Delivery</h2>
                  <p className="mb-45">
                  We work hard to make your time in the nursery convenient and effortless. You may explore our collection of plants on our website and quickly make an order by using the simple online ordering system that we have set up. Additionally, we offer dependable delivery services that make sure your plants get at their destination securely. Your nursery buying experience will be hassle-free thanks to our effective ordering and shipping procedures.
                  </p>
                </>
                {/* <ServiceHowItWork /> */}
                {/* <ServiceFaq /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterTwo />
    </>
  );
};

export default NurseryService;
