import React from "react";
import { Link } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";

const ServiceWidget = () => {
  return (
    <>
      <div className="widget service-list-widget mb-60">
        <h3 className="widget-title">Our Services</h3>
        <ul>
          <li>
            <Link to="/service/urban-forest-design">
              Urban Forestation
              <FaArrowRight />
            </Link>
          </li>

          <li>
            <Link to="/service/landscape-installation">
              Landscape Installation <FaArrowRight />
            </Link>
          </li>

          <li>
            <Link to="/service/plants-procurement">
              Plants Procurement <FaArrowRight />
            </Link>
          </li>

          <li>
            <Link to="/service/soil-mixture">
              Soil Mixture <FaArrowRight />
            </Link>
          </li>

          <li>
            <Link to="/service/plants-seeds">
              Plants Seeds <FaArrowRight />
            </Link>
          </li>
          
          <li>
            <Link to="/service/pest-moss">
              Peat Moss <FaArrowRight />
            </Link>
          </li>
          
          <li>
            <Link to="/service/maintenance-preservation">
              Maintenance & Preservation
              <FaArrowRight />
            </Link>
          </li>

          <li>
            <Link to="/service/tree-planting">
              Tree Planting <FaArrowRight />
            </Link>
          </li>
          
          <li>
            <Link to="/service/nursery-service">
              Nursery Service
              <FaArrowRight />
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
};

export default ServiceWidget;
