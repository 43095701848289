import React from "react";

import HeaderBottomTwo from "../../common/header/HeaderBottomTwo";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import breadcrumbBg from "../../assets/images/breadcrumb/Maintenance_Preservation.jpg";
import ServiceDetailsContent from "../../components/service-details/ServiceDetailsContent";
import ServiceHowItWork from "../../components/service-details/ServiceHowItWork";
import ServiceFaq from "../../components/service-details/ServiceFaq";
import QuoteWidget from "../../components/widgets/QuoteWidget";
import ContactWidget from "../../components/widgets/ContactWidget";
import ServiceWidget from "../../components/widgets/ServiceWidget";
import FooterTwo from "../../common/footer/FooterTwo";
import HeaderTop from "../../common/header/HeaderTop";
import ServiceDetailsImg1 from "../../assets/images/service/Maintenance.jpg";

const MaintenancePreservationService = () => {
  return (
    <>
      <HeaderTop />
      <HeaderBottomTwo />
      <Breadcrumb
        breadcrumbBg={breadcrumbBg}
        heading="Services Details"
        description="Maintenance & Preservation"
        currentPage="Services Details"
      />
      <div className="service-details pt-110 pb-110">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-4 pr-50">
              <ServiceWidget />
              <ContactWidget />
              {/* <QuoteWidget /> */}
            </div>
            <div className="col-xl-8 col-lg-8">
              <div className="service-details-wrapper">
                <>
                  <h2>Maintenance & Preservation</h2>
                  <p>
                  We're glad you're here to learn more about our top-notch nursery maintenance, preservation, forestry, and landscaping services in Saudi Arabia. Our area of expertise is in offering all-encompassing solutions for the upkeep, preservation, and improvement of nurseries, forests, and landscapes. No matter if you run a nursery, manage a forest, or work in landscaping, we have the knowledge and tools to meet your demands.
                  </p>
                <h2>Nursery Maintenance & Preservation</h2>
                  <p>
                  Our Nursery Maintenance & Preservation service aims to maintain the health and vitality of the plants you purchase from nurseries. Numerous services are available from us, such as routine watering, fertilization, pruning, pest control, and disease management. Experienced horticulturists on our staff are able to give specialized care to maximize plant growth and development since they are aware of the unique requirements of various plant types. Our goal is to keep your nursery plants looking beautiful and holding their value.
                  </p>
                  <img
                    src={ServiceDetailsImg1}
                    className="mt-35 mb-35"
                    alt=""
                    height={550}
                  />
                  <h2>Foresting and Landscape Services</h2>
                  <p>
                  Our landscaping and forest management services turn outdoor areas into lovely, environmentally friendly settings. We provide a comprehensive range of services to satisfy your unique needs, whether you're preparing a new forestation project or want to improve an existing landscape. We provide a variety of services, such as site analysis, plant and tree selection, soil preparation, planting, irrigation system installation, and continuous care. We are committed to building vibrant, rich landscapes that have a variety of advantages, including better air quality, less soil erosion, and aesthetic appeal.
                  </p>

                  <h2>Expert Team and Equipment</h2>
                  <p className="mb-45">
                  Our team of knowledgeable experts has the training, expertise, and specialized tools required to provide top-notch nursery management, preservation, forestry, and landscaping services. To assure the best outcomes, we use cutting-edge methods and keep current with industry trends. We stand out as a dependable nursery and landscaping service provider in Saudi Arabia because of our dedication to quality and careful attention to detail.
                  </p>
                  <h2>Consultation and Design</h2>
                  <p className="mb-45">
                  For the sake of the success of your nursery, forest, or landscape project, our team of landscape architects and horticultural specialists offers consultancy and design services. We work together with customers to fully grasp their vision, evaluate site circumstances, and create complete designs that are in line with their preferences and aspirations. We can develop outdoor areas that are both practical and aesthetically pleasing thanks to our knowledge of plant selection, space planning, and sustainable design concepts.
                  </p>
                </>
                {/* <ServiceHowItWork /> */}
                {/* <ServiceFaq /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterTwo />
    </>
  );
};

export default MaintenancePreservationService;
