import { CgChevronDown, CgChevronLeft } from "react-icons/cg";

const menuData = [
  {
    title: "Home",
    path: "/",
    iconClosed: <CgChevronLeft />,
    iconOpened: <CgChevronDown />,
    subNav: [],
  },

  {
    title: "About",
    path: "/about",
    iconClosed: <CgChevronLeft />,
    iconOpened: <CgChevronDown />,
    subNav: [],
  },

  {
    title: "Services",
    path: "#",
    iconClosed: <CgChevronLeft />,
    iconOpened: <CgChevronDown />,
    subNav: [
      {
        title: "Urban Forestation",
        path: "/service/urban-forest-design",
        cName: "sub-nav",
      },
      {
        title: "Landscape Installation",
        path: "/service/landscape-installation",
        cName: "sub-nav",
      },
      {
        title: "Plants Procurement",
        path: "/service/plants-procurement",
        cName: "sub-nav",
      },
      {
        title: "Soil Mixture",
        path: "/service/soil-mixture",
        cName: "sub-nav",
      },
      {
        title: "Plants Seeds",
        path: "/service/plants-seeds",
        cName: "sub-nav",
      },
      {
        title: "Peat Moss",
        path: "/service/pest-moss",
        cName: "sub-nav",
      },
      {
        title: "Maintenance & Preservation",
        path: "/service/maintenance-preservation",
        cName: "sub-nav",
      },
      {
        title: "Tree Planting",
        path: "/service/tree-planting",
        cName: "sub-nav",
      },
      {
        title: "Nursery Service",
        path: "/service/nursery-service",
        cName: "sub-nav",
      },
      
    ],
  },

  // {
  //   title: "Page",
  //   path: "#",

  //   iconClosed: <CgChevronLeft />,
  //   iconOpened: <CgChevronDown />,

  //   subNav: [
  //     {
  //       title: "Project 1",
  //       path: "/project-one",
  //       cName: "sub-nav",
  //     },
  //     {
  //       title: "Project 2",
  //       path: "/project-two",
  //       cName: "sub-nav",
  //     },
  //     {
  //       title: "Project Details",
  //       path: "/project-details",
  //     },
  //     {
  //       title: "Team",
  //       path: "/team",
  //     },
  //     {
  //       title: "Gallery",
  //       path: "/gallery",
  //     },
  //     {
  //       title: "Pricing",
  //       path: "/pricing",
  //     },
  //     {
  //       title: "FAQ",
  //       path: "/faq",
  //     },
  //     {
  //       title: "404",
  //       path: "/error",
  //     },
  //   ],
  // },

  // {
  //   title: "Blog",
  //   path: "#",

  //   iconClosed: <CgChevronLeft />,
  //   iconOpened: <CgChevronDown />,

  //   subNav: [
  //     {
  //       title: "Blog",
  //       path: "/blog",
  //       cName: "sub-nav",
  //     },
  //     {
  //       title: "Blog List",
  //       path: "/blog-list",
  //       cName: "sub-nav",
  //     },
  //     {
  //       title: "Blog Details",
  //       path: "/blog-details",
  //     },
  //   ],
  // },

  {
    title: "Contact",
    path: "/contact",
    subNav: [],
  },
];

export { menuData };
