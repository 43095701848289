import React from "react";

import HeaderBottomTwo from "../../common/header/HeaderBottomTwo";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import ServiceDetailsContent from "../../components/service-details/ServiceDetailsContent";
import ServiceHowItWork from "../../components/service-details/ServiceHowItWork";
import ServiceFaq from "../../components/service-details/ServiceFaq";
import QuoteWidget from "../../components/widgets/QuoteWidget";
import ContactWidget from "../../components/widgets/ContactWidget";
import ServiceWidget from "../../components/widgets/ServiceWidget";
import FooterTwo from "../../common/footer/FooterTwo";
import HeaderTop from "../../common/header/HeaderTop";

import breadcrumbBg from "../../assets/images/breadcrumb/plants-seeds.jpg";
import ServiceDetailsImg1 from "../../assets/images/service/plants-seeds-1.jpg";
const PlantsSeedsService = () => {
  return (
    <>
      <HeaderTop />
      <HeaderBottomTwo />
      <Breadcrumb
        breadcrumbBg={breadcrumbBg}
        heading="Services Details"
        description="Plants Seeds"
        currentPage="Services Details"
      />
      <div className="service-details pt-110 pb-110">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-4 pr-50">
              <ServiceWidget />
              <ContactWidget />
              {/* <QuoteWidget /> */}
            </div>
            <div className="col-xl-8 col-lg-8">
              <div className="service-details-wrapper">
                <>
                  <h2>Plants Seeds</h2>
                  <p>
                  We are committed to offering top-notch seeds that allow a range of plants to grow and develop successfully. We offer the knowledge and resources to meet your needs for plant seeds, whether you are a homeowner, a business developer, or a landscaping professional.
                  </p>
                  <h2>Extensive Seed Selection</h2>
                  <p>
                  A wide range of seeds appropriate for Saudi Arabia's particular soil and climate are available through our Plants Seeds service. Our seed collection is meticulously curated to contain a wide variety of plant species, such as native plants, ornamentals, flowers, herbs, veggies, and more. We offer the ideal seeds for you, whether you want to grow your own fresh vegetables or are seeking for seeds to improve the aesthetic of your garden.
                  </p>
                  <img
                    src={ServiceDetailsImg1}
                    className="mt-35 mb-35"
                    alt=""
                  />
                  <h2>Quality and Viability</h2>
                  <p>
                  The viability and quality of the seeds we provide are our top priorities. Our seeds come from trustworthy seed banks and vendors that have a reputation for offering top-notch seeds. We carefully choose seeds that have a wide genetic range, are free of illness, and have good germination rates. By doing this, you can be confident that the seeds you get have the highest possible opportunity of successfully germinating and growing, producing strong, healthy plants.
                  </p>

                  <h2>Expert Guidance and Advice</h2>
                  <p className="mb-45">
                  Our knowledgeable horticulture experts are available to offer you professional direction and advice on seed selection, planting methods, and correct plant care. We can assist you in selecting the appropriate seeds for your project since we are aware of the unique needs of various plant species. We are here to help you achieve effective germination and development whether you are an experienced gardener or a novice.
                  </p>
                  <h2>Specialized Seed Mixes</h2>
                  <p className="mb-45">
                  Along with individual seed packages, we also offer specialized seed mixtures made to meet certain gardening objectives. Whether you wish to grow a herb garden, or a wildflower meadow, or attract pollinators, our seed mixtures are made to make the process easier and guarantee a harmonic fusion of complementary species. These carefully curated seed mixtures offer a varied and well-balanced range of plant species.
                  </p>
                  <h2>Client Satisfaction</h2>
                  <p className="mb-45">
                  Our first goal is to make sure you are happy. We work hard to go above and beyond your expectations by providing premium seeds, first-rate customer support, and prompt order fulfillment. We appreciate your input and are always looking for ways to enhance our products and services to better meet your requirements.
                  </p>
                </>
                {/* <ServiceHowItWork /> */}
                {/* <ServiceFaq /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterTwo />
    </>
  );
};

export default PlantsSeedsService;
