import React from "react";

import HeaderBottomTwo from "../../common/header/HeaderBottomTwo";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import breadcrumbBg from "../../assets/images/breadcrumb/peat_moss.jpg";
import ServiceDetailsContent from "../../components/service-details/ServiceDetailsContent";
import ServiceHowItWork from "../../components/service-details/ServiceHowItWork";
import ServiceFaq from "../../components/service-details/ServiceFaq";
import QuoteWidget from "../../components/widgets/QuoteWidget";
import ContactWidget from "../../components/widgets/ContactWidget";
import ServiceWidget from "../../components/widgets/ServiceWidget";
import FooterTwo from "../../common/footer/FooterTwo";
import HeaderTop from "../../common/header/HeaderTop";
import ServiceDetailsImg1 from "../../assets/images/service/peat_moss.jpg";
const PestMossService = () => {
  return (
    <>
      <HeaderTop />
      <HeaderBottomTwo />
      <Breadcrumb
        breadcrumbBg={breadcrumbBg}
        heading="Services Details"
        description="Peat Moss"
        currentPage="Services Details"
      />
      <div className="service-details pt-110 pb-110">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-4 pr-50">
              <ServiceWidget />
              <ContactWidget />
              {/* <QuoteWidget /> */}
            </div>
            <div className="col-xl-8 col-lg-8">
              <div className="service-details-wrapper">
                <>
                  <h2>Peat Moss</h2>
                  <p>
                    Our expertise is in offering premium Peat Moss products that efficiently manage peats and
                    enhance the health of your plants and landscapes. Whether you're a house owner, a business developer, or a landscaping specialist, we have the knowledge and tools to meet your pest management needs.

                  </p>
                  <h2>Effective Pest Control</h2>
                  <p>
                    To assist you fight off a variety of pests that might harm your plants and landscapes, our Pest Moss service provides efficient pest control solutions. Pest Moss is a natural, environmentally responsible solution that serves as a physical barrier to keep pests from invading your plants. It offers a non-toxic, chemical-free approach to pest management, protecting the environment, your plants, pets, and all living things.
                  </p>
                  <img
                    src={ServiceDetailsImg1}
                    className="mt-35 mb-35"
                    alt=""
                  />
                  <h2>Organic and Sustainable Solution</h2>
                  <p>
                    In our Peat Moss service, organic and environmentally friendly methods are given priority. Our Peat Moss is obtained from reliable vendors who use ethical harvesting and processing practices. Because it is gathered sustainably, peat moss is a renewable resource that will be accessible to future generations. By deciding on Peat Moss, you help pest control become greener and more ecologically friendly.
                  </p>

                  <h2>Moisture Retention and Weed Suppression</h2>
                  <p className="mb-45">
                    Peat Moss has other advantages for your plants and landscapes in addition to its pest control capabilities. It serves as a kind of organic mulch, preventing water evaporation and retaining moisture in the soil. This is especially advantageous in Saudi Arabia's dry environment, where water conservation is essential. Through the creation of a physical barrier that prevents weed germination and establishment, Peat Moss also helps to control weed development.
                  </p>
                </>
                {/* <ServiceHowItWork /> */}
                {/* <ServiceFaq /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterTwo />
    </>
  );
};

export default PestMossService;
