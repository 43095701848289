import React from 'react';
import CountUp from 'react-countup';

const FunFact = () => {
    return (
        <div className="fun-fact-area pt-60 pb-60 theme-bg-2">
            <div className="container">
                <div className="row">
                    <div className="col-xl-4 col-lg-4 col-md-4">
                        <div className="single-counter text-center">
                            <h2><span className="counter"><CountUp end={15} /></span></h2>
                            <p>Completed Projects</p>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-4">
                        <div className="single-counter text-center">
                            <h2><span className="counter"><CountUp end={10} /></span></h2>
                            <p>Awards Winning</p>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-4">
                        <div className="single-counter text-center">
                            <h2><span className="counter"><CountUp end={100} /></span>%</h2>
                            <p>Client Satisfaction</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FunFact;