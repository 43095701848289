import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import AboutExperienceImg1 from '../../assets/images/banner/about-2.jpg';

const AboutExperienceData = [
    
    {
        id: uuidv4(),
        heading: 'Mission',
        description: 'We know that our country is a leading energy exporter and so have a significant responsibility to maintain global energy security. Our company pledges to run a business in a way so that our country will be increasing flora cover, helping struggle against erosion through cautiously planned afforestation initiatives. As most of the country is dry and desert, still there are quite a few plants that are majorly found in our country, and our mission is to plant these species on our land on a huge scale.',
    },

    {
        id: uuidv4(),
        heading: 'Vision',
        description: "It is a higher priority than at any time in recent eras to embrace a green way of life. Together we can re-establish our forest, make an environment for biodiversity, and have a positive social effect all over the country. Grow Saudi Green’s main aim is to be a part of the Kingdom Ruler's Vision 2030 arrangement to decrease its dependence on oil incomes and to work for the quality of life for the people of Saudia Arabia.<br/> Establishing 10 billion trees across Saudi Arabia is comparable to restoring 40 million hectares of debased land. The ongoing efforts of tree plantation have already shown enormous benefits to the Saudis, this won't just safeguard the climate yet additionally, make new business opportunities for the country.",
    },

    {
        id: uuidv4(),
        heading: 'Goal',
        description: 'It is a long established fact that a reader will be distracted by the content page when looking at its layout. The point of using Lorem Ipsum is that more-or-less normal distribution of letters,',
    },
    
];

const AboutExperienceItem = ({ heading, description, img }) => {
    return (
        <>
            <div className="single-experience">
                <h4>{heading}</h4>
                <p>{description}</p>
            </div>
        </>
    )
}

const AboutExperience = () => {
    return (
        <>
            <div className="experience-area pt-110 pb-110">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section-title text-center mb-70">
                                <span>We Have 20 Years of Experience</span>
                                <h2>We Give Awesome Landscape <br /> Gardening Services</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-4 col-lg-6 col-md-6 offset-xl-2 offset-lg-2 offset-md-2">
                            {AboutExperienceData.map((data) => (
                                <AboutExperienceItem
                                    key={data.id}
                                    heading={data.heading}
                                    description={data.description}
                                />
                            ))}
                        </div>
                        <div className="col-xl-6 col-lg-6 col-lg-6 pl-85 pr-0">
                            <div className="experience-img">
                                <img src={AboutExperienceImg1} alt="" />
                                <div className="experience-small-block">
                                    <h5>We are Since 2000 ! Over 200 Awards </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AboutExperience;