import React from "react";
import { Link } from "react-router-dom";
import { menuData } from "../../unit/menuItems";

const Nav = () => {
  console.log(menuData);
  return (
    <div className="main-menu text-center">
      <nav id="mobile-menu">
        <ul>
          {menuData.map((v, i) => {
            return (
              <>
                <li>
                  <Link to={v["path"]}>{v["title"]}</Link>
                  {typeof v["subNav"] != "undefined" &&
                  v["subNav"].length > 0 ? (
                    <ul className="submenu">
                      {v["subNav"].map((c, k) => {
                        return (
                          <li>
                            <Link to={c["path"]}>{c["title"]}</Link>
                          </li>
                        );
                      })}
                    </ul>
                  ) : null}
                </li>
              </>
            );
          })}
        </ul>
      </nav>
    </div>
  );
};

export default Nav;
