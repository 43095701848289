import React from 'react';
import {Link} from 'react-router-dom';
import AboutBlockImg1 from '../../assets/images/about/about-large.jpg';
import AboutBlockImg2 from '../../assets/images/about/about-small.jpg';
import AboutBlockImg3 from '../../assets/images/about/about-author-avatar.jpg';

const AboutBlock = () => {
    return (
        <>
            <div className="about-area gray-bg-2 pt-110 pb-110">
                <div className="container">
                    <div className="row d-flex align-items-center">
                        <div className="col-xl-6 col-lg-6 col-md-6 pl-50 pr-50">
                            <div className="about-img-style-2">
                                <img src={AboutBlockImg1} alt="" />
                                <div className="about-small-img">
                                    <img src={AboutBlockImg2} alt="" />
                                </div>
                                <div className="about-experience">
                                    <h2>20+</h2>
                                    <span>Years Of <br /> Experiences</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6">
                            <div className="about-text-wrapper">
                                <div className="section-title mr-100 mb-40">
                                    <span>About</span>
                                    <h2 className="mb-30">Grow Saudia Green is a Saudi company established in the Kingdom of Saudia Arabia.</h2>
                                    <p> Our talented experts and devoted team provide you numerous options on building a greener and cleaner society. The significance of the agriculture area has required the necessity for quality seeds and planting materials, which has expanded the growth of our business. With practical experience in the corporate sector for a long time, you can trust us to track down the best answer for you. You'll find our work in workplaces, display areas and public structures from one side of the country to the other. Not only on small projects, but Grow Saudia Green has also worked for high-profile agricultural developments in the entire Kingdom. Our prime goal is to make Saudia an emerald environment to live in.</p>
                                </div>
                                <div className="row">
                                    <div className="col-xl-5 col-lg-12">
                                        <Link to="/" className="l-btn">Read More</Link>
                                    </div>
                                    <div className="col-xl-7 col-lg-12">
                                        <div className="about-author-avatar-wrapper d-flex align-items-center">
                                            <img src={AboutBlockImg3} alt="" />
                                            <div className="about-author-avatar-content">
                                                <h5>Nicolas Jurgan</h5>
                                                <span>Founder Landscape</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AboutBlock;