import React from "react";

import HeaderBottomTwo from "../../common/header/HeaderBottomTwo";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import breadcrumbBg from "../../assets/images/breadcrumb/urban-foresting.jpg";
import ServiceDetailsContent from "../../components/service-details/ServiceDetailsContent";
import ServiceHowItWork from "../../components/service-details/ServiceHowItWork";
import ServiceFaq from "../../components/service-details/ServiceFaq";
import QuoteWidget from "../../components/widgets/QuoteWidget";
import ContactWidget from "../../components/widgets/ContactWidget";
import ServiceWidget from "../../components/widgets/ServiceWidget";
import FooterTwo from "../../common/footer/FooterTwo";
import HeaderTop from "../../common/header/HeaderTop";
import ServiceDetailsImg1 from "../../assets/images/service/urban-foresting-1.jpg";
const UrbanForestService = () => {
  return (
    <>
      <HeaderTop />
      <HeaderBottomTwo />
      <Breadcrumb
        breadcrumbBg={breadcrumbBg}
        heading="Services Details"
        description="Urban Forest Design"
        currentPage="Services Details"
      />
      <div className="service-details pt-110 pb-110">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-4 pr-50">
              <ServiceWidget />
              <ContactWidget />
              {/* <QuoteWidget /> */}
            </div>
            <div className="col-xl-8 col-lg-8">
              <div className="service-details-wrapper">
                <>
                  <h2>Know more us of our Urban Forest Design Services.</h2>
                  <p>
                    At our company, we are passionate about developing
                    sustainable, energetic urban environments that include
                    nature. With the goal of transforming cities into green
                    havens, improving resident quality of life, and encouraging
                    environmental stewardship, our urban forest design services.
                  </p>
                  <br />
                  <p>
                    Our team of professionals is committed to creating and
                    executing urban forest solutions that maximise the
                    ecological, social, and economic advantages of green
                    infrastructure. We have years of experience in landscape
                    architecture and urban planning.
                  </p>
                  <img
                    src={ServiceDetailsImg1}
                    className="mt-35 mb-35"
                    alt=""
                  />
                  <h2>What sets us apart:</h2>
                  <p>
                    We support a holistic approach to urban forest design that
                    takes into account aspects like site analysis, species
                    choice, ecosystem services, and long-term upkeep. We provide
                    specialised solutions that specifically address problems and
                    objectives by analysing the special qualities of each place.
                    <br />
                    Sustainable Design: Resilience and sustainability are given
                    top priority in our urban forest designs. To increase
                    biodiversity, lessen the effects of climate change, and
                    boost ecosystem health generally, we integrate native plant
                    species, stormwater management strategies, and green
                    infrastructure components.
                    <br />
                    Collaboration is important to us, thus we actively involve
                    stakeholders in every stage of the design process. We make
                    sure that our urban forest designs meet the needs and goals
                    of the people who will benefit from them by incorporating
                    local communities, governmental bodies, and pertinent
                    organisations. Combined, we can
                  </p>

                  <h2>Our Urban Forest Design Services include:</h2>
                  <p className="mb-45">
                    Site Assessment: We carry out in-depth site analyses to
                    assess the situation as it is, spot opportunities, and
                    handle any potential issues. This involves looking at
                    site-specific elements including the type of soil, sunlight
                    exposure, water accessibility, and others.
                    <br />
                    <br />
                    Design Concept: Using the site assessment as a guide, we
                    create design concepts that describe the spatial
                    organisation, vegetation zones, and essential components of
                    the urban forest. Before advancing to the detailed design
                    stage, this stage enables feedback and refinement.
                    <br />
                    <br />
                    Our team produces extensive plans and thorough documentation
                    that outline plant choices, hardscape components, irrigation
                    systems, and upkeep instructions. These papers act as a
                    guide for execution and make sure that the idea is
                    successfully brought to life.
                  </p>
                </>
                {/* <ServiceHowItWork /> */}
                {/* <ServiceFaq /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterTwo />
    </>
  );
};

export default UrbanForestService;
