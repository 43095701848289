import React from "react";
import { Link } from "react-router-dom";
import FooterBG from "../../assets/images/footer-1.jpg";
import {
  FaPhoneAlt,
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaPinterestP,
  FaAngleDoubleRight,
} from "react-icons/fa";

const FooterOne = () => {
  var today = new Date();
  var year = today.getFullYear();
  return (
    <>
      <footer>
        <div
          className="footer-top bg-property theme-bg-2 pt-110 pb-80"
          style={{ backgroundImage: `url(${FooterBG})` }}
        >
          <div className="container">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                <div className="footer-widget-1">
                  <div className="footer-title">
                    <h4>Contact Us</h4>
                  </div>
                  <div className="footer-text">
                    <p>
                      If you have any questions or need help, feel free to
                      contact with our team.
                    </p>
                  </div>
                  <div className="footer-info">
                    <Link to="/">
                      <FaPhoneAlt /> +966-564153911
                    </Link>
                  </div>
                  <div className="footer-text">
                    <p>
                      Ibn Dabag 2345 Ar Rawda District, Jeddah, Kingdom of
                      Saudia Arabia
                    </p>
                  </div>
                  <div className="footer-info">
                    <Link to="/">
                      <FaPhoneAlt /> Get Directions
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-2 col-md-6 col-sm-12">
                <div className="footer-widget-2">
                  <div className="footer-title">
                    <h4>Quick Links</h4>
                  </div>
                  <div className="footer-menu">
                    <ul>
                      <li>
                        <Link to="/contact">Contact Us</Link>
                      </li>
                     
                      <li>
                        <Link to="/about">About Company</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-2 col-md-6 col-sm-12">
                <div className="footer-widget-3">
                  <div className="footer-title">
                    <h4>Our Services</h4>
                  </div>
                  <div className="footer-menu">
                    <ul>
                      <li>
                        <Link to="/service/urban-forest-design">Urban Forestation</Link>
                      </li>
                      <li>
                        <Link to="/service/landscape-installation">Landscape Installation</Link>
                      </li>
                      <li>
                        <Link to="/service/plants-procurement">Plants Procurement</Link>
                      </li>
                      <li>
                        <Link to="/service/plants-seeds">Plants Seeds</Link>
                      </li>
                      <li>
                        <Link to="/service/tree-planting">Tree Planting</Link>
                      </li>
                      <li>
                        <Link to="/service/nursery-service">Nursery Service</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                <div className="footer-widget-4">
                  <div className="footer-social mt-35">
                    <h5>Folllow Us:</h5>
                    <ul>
                      <li>
                        <Link to="/">
                          <FaFacebookF />
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                          <FaTwitter />
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                          <FaInstagram />
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                          <FaPinterestP />
                        </Link>
                      </li>
                    </ul>
                  </div>
                  {/* <div className="footer-newsletter mt-40">
                                        <input type="text" placeholder="Email Address" />
                                        <button><FaAngleDoubleRight /></button>
                                    </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-bottom theme-bg-1 pt-40 pb-30">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                <div className="copyright">
                  <p>
                    © {year}
                    <Link to="https://contrivermate.com/">Contriver Mate</Link>.
                    All right reserved.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default FooterOne;
