import React from "react";

import HeaderBottomTwo from "../../common/header/HeaderBottomTwo";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import ServiceDetailsContent from "../../components/service-details/ServiceDetailsContent";
import ServiceHowItWork from "../../components/service-details/ServiceHowItWork";
import ServiceFaq from "../../components/service-details/ServiceFaq";
import QuoteWidget from "../../components/widgets/QuoteWidget";
import ContactWidget from "../../components/widgets/ContactWidget";
import ServiceWidget from "../../components/widgets/ServiceWidget";
import FooterTwo from "../../common/footer/FooterTwo";
import HeaderTop from "../../common/header/HeaderTop";

import breadcrumbBg from "../../assets/images/breadcrumb/soil-mixture.jpg";
import ServiceDetailsImg1 from "../../assets/images/service/soil-mixture-1.jpg";
const SoilMixtureService = () => {
  return (
    <>
      <HeaderTop />
      <HeaderBottomTwo />
      <Breadcrumb
        breadcrumbBg={breadcrumbBg}
        heading="Services Details"
        description="Soil Mixture"
        currentPage="Services Details"
      />
      <div className="service-details pt-110 pb-110">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-4 pr-50">
              <ServiceWidget />
              <ContactWidget />
            </div>
            <div className="col-xl-8 col-lg-8">
              <div className="service-details-wrapper">
                <>
                  <h2>Soil Mixture</h2>
                  <p>
                    We take pleasure in offering premium soil mixtures that are especially created to satisfy the particular demands of your landscaping projects. We have the knowledge and tools necessary to provide specialized soil combinations that encourage strong plant development and long-lasting landscapes, whether you are a homeowner, a business developer, or a landscaping professional.
                  </p>


                  <h2>Customized Soil Mixtures</h2>
                  <p>
                    We are aware that various landscaping tasks demand various soil chemistry. We offer tailored mixes for your project's particular needs through our soil mixture service. Whether you want soil for flowerbeds, lawns, or garden beds, we meticulously pick and blend the right components to create a nutrient-rich, well-draining soil combination that promotes optimum plant development.
                  </p>
                  <img
                    src={ServiceDetailsImg1}
                    className="mt-35 mb-35"
                    alt=""
                  />
                  <h2>High-Quality Ingredients</h2>
                  <p>
                    To assure the efficacy and long-term success of our soil combinations, we only use premium components. To produce a healthy and productive soil combination, our team of professionals chooses quality ingredients such as organic matter, compost, sand, peat moss, and other helpful additives. To guarantee that our ingredients match the highest standards, we pay close attention to their quality and place of origin.
                  </p>
                  <h2>Soil Analysis and Testing</h2>
                  <p>
                    We offer thorough soil analysis and testing services in order to give you the best soil mixture. Our specialists do an analysis of the existing soil at your location, taking into account variables like pH levels, nutrient content, and texture. Based on the findings of the research, we suggest the proper soil amendments and develop a special soil blend that corrects any deficiencies and improves plant health.
                  </p>
                  <h2>Expert Guidance and Support</h2>
                  <p>
                    Our team of horticulture experts is available to offer professional advice and assistance throughout the decision-making and application processes for soil mixtures. We are aware of the individual needs of many plant species and can provide you with advice on the best soil mixture for your project. We are here to help you at every stage, whether you are starting a new garden or remodeling an old landscape.
                  </p>

                </>
                {/* <ServiceHowItWork /> */}
                {/* <ServiceFaq /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterTwo />
    </>
  );
};

export default SoilMixtureService;
