import React from "react";

import HeaderBottomTwo from "../../common/header/HeaderBottomTwo";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import ServiceDetailsContent from "../../components/service-details/ServiceDetailsContent";
import ServiceHowItWork from "../../components/service-details/ServiceHowItWork";
import ServiceFaq from "../../components/service-details/ServiceFaq";
import QuoteWidget from "../../components/widgets/QuoteWidget";
import ContactWidget from "../../components/widgets/ContactWidget";
import ServiceWidget from "../../components/widgets/ServiceWidget";
import FooterTwo from "../../common/footer/FooterTwo";
import HeaderTop from "../../common/header/HeaderTop";

import breadcrumbBg from "../../assets/images/breadcrumb/landscape-installation.jpg";
import ServiceDetailsImg1 from "../../assets/images/service/landscape-installation-1.jpg";
const LandscapeInstallationService = () => {
  return (
    <>
      <HeaderTop />
      <HeaderBottomTwo />
      <Breadcrumb
        breadcrumbBg={breadcrumbBg}
        heading="Services Details"
        description="Landscape Installation"
        currentPage="Services Details"
      />
      <div className="service-details pt-110 pb-110">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-4 pr-50">
              <ServiceWidget />
              <ContactWidget />
            </div>
            <div className="col-xl-8 col-lg-8">
              <div className="service-details-wrapper">
                <>
                  <h1>Landscape Installation</h1>
                  <p>
                 We are dedicated to constructing stunning outdoor areas that highlight this area's natural beauty. Regardless matter whether you are a homeowner, a business developer, or a landscaping specialist, we have the knowledge and tools to realize your idea.
                  </p>

                 
                  <h2>Expert Landscape Installation Services</h2>
                  <p>
                  We are a premier supplier of landscape installation services in Saudi Arabia with years of expertise in the field. To design and build landscapes that surpass your expectations, our team of highly qualified specialists blends aesthetic inspiration with technical ability. We take pleasure in producing outstanding outcomes that not only improve the appearance of your outdoor area but also take into account the particular climatic and cultural setting of Saudi Arabia.
                  </p>
                  <img
                    src={ServiceDetailsImg1}
                    className="mt-35 mb-35"
                    alt=""
                  />
                 <h2>Comprehensive Landscape Design</h2>
                  <p>
                  We are aware that every terrain is different and needs a tailored strategy. Your tastes, functional needs, and site peculiarities are thoroughly understood by our devoted team of landscape architects and designers via direct collaboration with you. We create thorough landscape plans that cover everything from plant selection to hardscape components, water features, and lighting using cutting-edge design tools and a profound grasp of the local flora.
                  </p>
                 <h2>Expert Plant Selection and Installation</h2>
                  <p>
                  Every landscaping project depends on the proper selection and placement of plants. Our team of horticulturists carefully chooses plant species that are compatible with the soil and climate of Saudi Arabia. In order to preserve the long-term health and vitality of your landscape, we take into account variables like sun exposure, soil quality, and water needs. In order to provide plants with the greatest possible start for development and establishment, our qualified specialists install them using procedures that are at the forefront of the industry.
                  </p>
                 <h2>Quality Hardscape Elements</h2>
                  <p>
                  Along with plants, we are experts in the construction of high-quality hardscape components that enhance the use, resilience, and aesthetic appeal of your landscape. We work with a number of materials, including natural stone, pavers, and concrete, to create cogent and gorgeous outdoor living areas, from paths and patios to retaining walls and ornamental structures. Your hardscape features will be constructed to resist the rigors of the Saudi Arabian environment thanks to our meticulous attention to detail and skilled craftsmanship.
                  </p>
                 <h2>Customer satisfaction</h2>
                  <p>
                  we're devoted to going above and beyond for our customers. We work hard to keep lines of communication open, handle projects in a transparent manner, and finish each one on schedule. Our objective is to design landscapes that satisfy your needs while also improving your quality of life. We respect your pleasure and put out great effort to provide exceptional results you can be proud of for years to come.
                  </p>
                </>
                {/* <ServiceHowItWork /> */}
                {/* <ServiceFaq /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterTwo />
    </>
  );
};

export default LandscapeInstallationService;
