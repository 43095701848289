import React from "react";

import HeaderBottomTwo from "../../common/header/HeaderBottomTwo";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import ServiceDetailsContent from "../../components/service-details/ServiceDetailsContent";
import ServiceHowItWork from "../../components/service-details/ServiceHowItWork";
import ServiceFaq from "../../components/service-details/ServiceFaq";
import QuoteWidget from "../../components/widgets/QuoteWidget";
import ContactWidget from "../../components/widgets/ContactWidget";
import ServiceWidget from "../../components/widgets/ServiceWidget";
import FooterTwo from "../../common/footer/FooterTwo";
import HeaderTop from "../../common/header/HeaderTop";

import breadcrumbBg from "../../assets/images/breadcrumb/tree-planting.jpg";
import ServiceDetailsImg1 from "../../assets/images/service/tree-planting-1.jpg";

const TreePlantingService = () => {
  return (
    <>
      <HeaderTop />
      <HeaderBottomTwo />
      <Breadcrumb
        breadcrumbBg={breadcrumbBg}
        heading="Services Details"
        description="Tree Planting"
        currentPage="Services Details"
      />
      <div className="service-details pt-110 pb-110">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-4 pr-50">
              <ServiceWidget />
              <ContactWidget />
            </div>
            <div className="col-xl-8 col-lg-8">
              <div className="service-details-wrapper">
                <>
                  <h2>Discover our Tree Planting Services</h2>
                  <p>
                    Through our tree planting services, we are committed to
                    fostering a more environmentally friendly and healthy
                    environment. The preservation of biodiversity, the
                    enhancement of air quality, and the development of a more
                    sustainable future are all made possible by trees. With our
                    knowledge and commitment, we provide thorough tree planting
                    solutions catered to your individual requirements.
                  </p>

                  <h2>Why choose our Tree Planting Services:</h2>
                  <p>
                    Expertise and Experience: With years of experience in tree
                    planting, our team is made up of knowledgeable arborists and
                    tree care specialists. We are very knowledgeable about
                    different tree species, their growth requirements, and the
                    most effective procedures for planting and establishing
                    trees.
                    <br />
                    Site Evaluation and Species Selection: We carefully study
                    each site to consider its soil characteristics, exposure to
                    sunlight, water availability, and other elements. We suggest
                    appropriate tree species that are compatible with your
                    aesthetic preferences and environmental objectives and are
                    well-suited to the site's characteristics based on our
                    assessment.
                    <br />
                    Techniques for Proper Tree Planting: To ensure that trees
                    are installed and grown in correctly, we use
                    industry-recognized methods for planting. In order to
                    provide trees the best possible start for a healthy growth,
                    our team adheres to recommendations for planting depth, root
                    ball preparation, backfilling, and mulching.
                    <br />
                    <br />
                    Care & Upkeep: We remain dedicated to your trees long after
                    they are planted. On post-planting care and upkeep, such as
                    watering regimens, pruning, fertilisation, and pest control,
                    we offer advice. Our mission is to promote your trees'
                    long-term health and vitality, ensuring their continued
                    success for many years to come.
                    <br />
                    <br />
                    Large-Scale Planting initiatives: We have the capacity and
                    know-how to manage large-scale tree planting initiatives,
                    whether you're looking to construct a green corridor, a city
                    forest, or just want to improve a public area. To guarantee
                    that your idea is implemented successfully, our team can
                    help with project management, logistics, and coordination.
                    <br />
                    <br />
                    Community Involvement: We think it's important to involve
                    and involve the neighbourhood in our tree-planting projects.
                    To promote environmental stewardship, schedule tree-planting
                    activities, and raise awareness of the advantages of trees,
                    we work with local schools, businesses, and people.
                  </p>

                  <img
                    src={ServiceDetailsImg1}
                    className="mt-35 mb-35"
                    alt=""
                  />
                  <h2>Our Tree Planting Services include:</h2>
                  <p className="mb-45">
                    Residential Tree Planting: With properly chosen and expertly
                    planted trees, you may improve the appearance, shade, and
                    environmental impact of your property.
                    <br />
                    <br />
                    Commercial and Municipal Tree Planting: Through thoughtful
                    tree planting, commercial projects, parks, streetscapes, and
                    public areas can be transformed into lively, green settings.
                    <br />
                    <br />
                    Reforestation and Habitat Restoration: Planting trees in
                    deteriorated regions will help restore ecosystems, increase
                    biodiversity, and improve natural habitats.
                    <br />
                    <br />
                    Development of Urban Forests: Join forces with us to build
                    resilient, sustainable urban forests that will enhance the
                    quality of life for locals while reducing the impact of
                    urban heat islands.
                    <br />
                    <br />
                    By selecting our tree planting services, you are actively
                    contributing to environmental preservation and improving
                    your neighbourhood.
                    <br />
                    <br />
                    Contact us today to learn more about our Tree Planting
                    Services and how we can help you create a greener future.
                  </p>
                </>
                {/* <ServiceHowItWork /> */}
                {/* <ServiceFaq /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterTwo />
    </>
  );
};

export default TreePlantingService;
