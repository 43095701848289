import React from "react";

import HeaderBottomTwo from "../../common/header/HeaderBottomTwo";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import ServiceDetailsContent from "../../components/service-details/ServiceDetailsContent";
import ServiceHowItWork from "../../components/service-details/ServiceHowItWork";
import ServiceFaq from "../../components/service-details/ServiceFaq";
import QuoteWidget from "../../components/widgets/QuoteWidget";
import ContactWidget from "../../components/widgets/ContactWidget";
import ServiceWidget from "../../components/widgets/ServiceWidget";
import FooterTwo from "../../common/footer/FooterTwo";
import HeaderTop from "../../common/header/HeaderTop";

import breadcrumbBg from "../../assets/images/breadcrumb/plant-growing.jpg";
import ServiceDetailsImg1 from "../../assets/images/service/plant-growing-1.jpg";
const PlantsProcurement = () => {
  return (
    <>
      <HeaderTop />
      <HeaderBottomTwo />
      <Breadcrumb
        breadcrumbBg={breadcrumbBg}
        heading="Services Details"
        description="Plants Procurement"
        currentPage="Services Details"
      />
      <div className="service-details pt-110 pb-110">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-4 pr-50">
              <ServiceWidget />
              <ContactWidget />
            </div>
            <div className="col-xl-8 col-lg-8">
              <div className="service-details-wrapper">
                <>
                  <h2>Plants Procurement</h2>
                  <p>
                    We're glad you're here to learn more about our top-notch plant procurement service in Saudi Arabia. We take pleasure in offering a wide variety of plant-sourcing options to satisfy your unique landscaping requirements. We offer the knowledge and capabilities to meet your plant procurement needs, whether you're a homeowner, a business developer, or a landscaping professional.
                  </p>


                  <h2>Extensive Plant Selection</h2>
                  <p>
                    We are aware that choosing the appropriate plants is essential to the success of any landscaping project. Our plant procurement service provides a wide variety of plant species that are suited for Saudi Arabia's particular soil and climate. We guarantee a wide variety that fits your tastes and project requirements, from native plants that flourish in dry regions to exotic types that provide a touch of diversity and beauty.
                  </p>
                  <img
                    src={ServiceDetailsImg1}
                    className="mt-35 mb-35"
                    alt=""
                  />
                  <h2>Quality and Health of Plants</h2>
                  <p>
                    The health and quality of the plants we buy come first. In order to find strong, healthy plants that are suitable for the Saudi Arabian environment, our experienced staff collaborates closely with respected nurseries and suppliers. We carefully examine every plant to make sure it is free of ailments, pests, and other problems that can jeopardize its long-term survival. Your landscaping initiatives will be a success thanks to our dedication to supplying top-notch plants.
                  </p>
                  <h2>Expert Guidance and Advice</h2>
                  <p>
                    Throughout the plant-buying process, you can count on the assistance of our knowledgeable horticulture consultants. We are aware of the particular needs of many plant species and can assist you in choosing the best plants for your project depending on elements like soil type, water availability, and exposure to sunlight. We are here to help you every step of the process, whether you need aid with plant selection, quantity estimation, or care instructions.
                  </p>
                  <h2>Sustainability and Environmental Responsibility</h2>
                  <p>
                  In our plant procurement procedures, we are devoted to supporting environmental responsibility and sustainability. In order to reduce the use of chemical pesticides and fertilizers, we give priority to purchasing plants from nurseries that use sustainable growing techniques. To conserve water resources and advance biodiversity in Saudi Arabia, we also advocate the use of native and drought-tolerant plant species.
                  </p>
                  <h2>Client Satisfaction</h2>
                  <p>
                  Our first goal is to make sure you are happy. We work hard to go above and beyond your expectations by providing premium plants, outstanding customer service, and on-time project completion. Our meticulous efforts are directed toward making sure that your landscaping initiatives are a complete success.
                  </p>

                </>
                {/* <ServiceHowItWork /> */}
                {/* <ServiceFaq /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterTwo />
    </>
  );
};

export default PlantsProcurement;
